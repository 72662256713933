@tailwind base;
@tailwind components;
@tailwind utilities;

@import "~@flaticon/flaticon-uicons/css/all/all";

body {
  background-color: #fef2f2;
}

h1,
h2,
h3,
h4 {
  color: #991b1b;
  font-weight: bold;
}

h1 {
  font-size: 3rem;
}

h2 {
  font-size: 2rem;
}

h3 {
  font-size: 1.5rem;
}

h4 {
  font-size: 1rem;
}

input {
  border: 2px solid #e2e8f0;
  border-radius: 4px;
  padding: 12px;
}

input:focus {
  outline: none;
  border-color: #991b1b;
}

.settings label {
  font-size: 14pt;
}

select {
  border: 2px solid #e2e8f0;
  min-width: 300px;
  border-radius: 4px;
  padding: 12px;
  font-size: 9pt;
}

select:focus {
  outline: none;
  border-color: #991b1b;
}

.main-container {
  padding: 12px;
  display: flex;
  justify-content: start;
  text-align: center;
  gap: 12px;
  min-height: 100vh;
}

@media (max-width: 1024px) {
  .main-container {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

.box {
  background-color: white;
  padding: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  border: 1.5px solid #fecaca;
  box-shadow: 5px 5px 30px 2px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
}

@media (max-width: 1024px) {
  .box {
    width: 100%;
  }
}

.navbar {
  background-color: white;
  padding: 8px 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  border: 1.5px solid #fecaca;
  box-shadow: 5px 5px 30px 2px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  margin: 12px 12px 0px 12px;
  min-height: 72px;
}

@media (max-width: 768px) {
  .navbar {
    flex-direction: column;
    padding: 32px 16px;
  }
}

.overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: black;
  opacity: 0.8;
}

.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 70%;
  height: 80%;
  overflow: scroll;
  background-color: white;
  padding: 32px;
  display: flex;
  flex-direction: column;
  align-items: start;
  text-align: left;
  gap: 12px;
  border: 1.5px solid #d1d5db;
  box-shadow: 5px 5px 30px 2px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
}

@media (max-width: 768px) {
  .modal {
    width: 95%;
    height: 95%;
  }
}

.custom-loader {
  width: 50px;
  height: 50px;
  --c: radial-gradient(farthest-side, #991b1b 92%, #0000);
  background:
    var(--c) 50% 0,
    var(--c) 50% 100%,
    var(--c) 100% 50%,
    var(--c) 0 50%;
  background-size: 12px 12px;
  background-repeat: no-repeat;
  animation: s7 1s infinite;
}

@keyframes s7 {
  to {
    transform: rotate(.5turn)
  }
}

@media (max-width: 480px) {
  .xs-custom {
    flex-direction: column;
  }
}

.mini-custom-loader {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background:
    radial-gradient(farthest-side, #991b1b 94%, #0000) top/8px 8px no-repeat,
    conic-gradient(#0000 30%, #991b1b);
  -webkit-mask: radial-gradient(farthest-side, #0000 calc(100% - 8px), #000 0);
  animation: s3 1s infinite linear;
}

@keyframes s3 {
  100% {
    transform: rotate(1turn)
  }
}

@media print {

  .fixed {
    position: static !important;
    /* Cambia il layout per la stampa */
    inset: auto !important;
  }

  .page {
    page-break-after: always;
    /* Forza l'interruzione di pagina */
  }

  .page:last-of-type {
    page-break-after: auto;
    /* Nessuna interruzione dopo l'ultima pagina */
  }

  .navbar {
    display: none;
  }

  button {
    display: none;
    /* Nasconde il bottone di stampa */
  }
}